import React, { useContext, useEffect } from 'react';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';
import { navigate } from 'gatsby-link';
import { useMfaLogin } from 'src/hooks/use-mfa-login';

import { Translate } from 'src/components/Translate';
import { LoadingThrobber } from 'src/components/atom/icons';

const getTargetUrl = (urlParam: string) => {
  const params = new URLSearchParams(window.location.search);
  const targetUrl = params.get(urlParam);

  if (!targetUrl) return null;

  params.delete(urlParam);
  return `/${targetUrl}?${params.toString()}`;
};

const Login: React.FC = () => {
  const loginTargetUrl = getTargetUrl('target_url');
  const { isReady } = useContext(SplitContext);
  const treatments = useTreatments([Split.FeaturePartnerOfferPageRedirection]);
  const hasPartnerOfferRedirect = treatments[Split.FeaturePartnerOfferPageRedirection].treatment === Treatment.On;
  const i18n = { loading: 'pages.login.loading' };
  const login = useMfaLogin();

  useEffect(() => {
    if (isReady) {
      if (!hasPartnerOfferRedirect || !loginTargetUrl) {
        // Navigate to benefits page when flag is OFF
        navigate('/benefits');
      } else {
        const params = { state: { targetUrl: loginTargetUrl } };
        login(params);
      }
    }
  }, [isReady]);

  return (
    <div style={{ textAlign: 'center' }}>
      <LoadingThrobber width={40} height={40} />
      <p className="text_3">
        <Translate resourceKey={i18n.loading} />
      </p>
    </div>
  );
};

export default Login;
